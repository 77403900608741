import React, { useState, useEffect } from "react";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import { CardSwiper } from "../../components";
import "./Work.scss";

const Work = () => {
  const [filterWork, setFilterWork] = useState([]);

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      data = duplicateFilter(data);
      setFilterWork(data);
    });
  }, []);

  const duplicateFilter = (works) => {
    const seenIds = new Set();
    const filteredWorks = works.filter((work) => {
      if (!seenIds.has(work._id)) {
        seenIds.add(work._id);
        return true;
      }
      return false;
    });
    return filteredWorks;
  };

  return (
    <div className="app__work-wrapper">
      <h2 className="head-text">Projects that I've worked</h2>
      <CardSwiper filterWork={filterWork} />
    </div>
  );
};

export default AppWrap(MotionWrap(Work, "app__work"), "work", "app__primarybg");
