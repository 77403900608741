import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import "./Header.scss";

const introVariant = {
  whileInView_1: {
    y: [100, 0], 
    opacity: [0, 1],
    transition: {
      delay: 1.5, 
      duration: 1
    },
  },
  whileInView_2: {
    y: [100, 0], 
    opacity: [0, 1],
    transition: {
      delay: 2.5, 
      duration: 1
    },
  },
};

const Header = () => {
  return (
    <div className="app__header app__flex">
      <div>
      <motion.div
        
        whileInView={{ x: [-200, 0], opacity: [0, 1] }}
        transition={{  duration: 1.5 }}
        className="app__header-intro"
      >
        <h1>Hello World</h1>

        <h2>
          I'm Fabio Huang, <br /> Someone who programs <br />
          just for fun.
        </h2>

      </motion.div>
      <motion.div
        whileInView={introVariant.whileInView_1}
        variant={introVariant}

        className="app__header-intro"
      >
        <p>
          A software engineer.
        </p>
      </motion.div>
      <motion.div
        whileInView={introVariant.whileInView_2}
        className="app__header-intro"
        variant={introVariant}
      >
        <p>
          Still thinking what to do with Life.
        </p>
      </motion.div>
      </div>
    </div>
  );
};

export default AppWrap(Header, "home");
