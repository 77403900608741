import React from 'react'
import {BsTwitter, BsInstagram, BsGithub, BsLinkedin} from 'react-icons/bs'


const SocialMedia = ()=> {
    return (
        <div className='app__social'>
            <div>
                <a href="https://twitter.com/FabMiguel0312"><BsTwitter/></a>
            </div>
            <div>
                <a href="https://www.instagram.com/fab_miguel0312/"><BsInstagram/></a>
            </div>
            <div>
                <a href="https://github.com/FabioMiguel2000"><BsGithub/></a>
            </div>
            <div>
                <a href="https://www.linkedin.com/in/f%C3%A1bio-huang-1331bb232/"><BsLinkedin/></a>
            </div>
        </div>
    )
}

export default SocialMedia