import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { urlFor } from "../../client";

import CardItem from "./CardItem/CardItem";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CardSwiper.scss";
import { Pagination, Navigation } from "swiper/modules";

function ToDate(a) {
  return new Date(a.substring(0, a.length - 1).toString());
}

function CardSwiper({ filterWork }) {
  const filterWorkBySize = (works, size = 99) => {
    if (works.length <= size) return works;
    return works.slice(0, size);
  };
  const getGithubData = () => {
    return CardItem({
      imageUrl: images.github_logo,
      title: "Find Out More on GitHub",
      description: "Find out more on my GitHub Page",
      projectLink: "https://github.com/FabioMiguel2000",
      codeLink: "https://github.com/FabioMiguel2000",
    });
  };
  return (
    <div className="app__card-swiper">
      <motion.div
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__cards-container"
      >
        <Swiper
          slidesPerView={1}
          centeredSlides={false}
          spaceBetween={80}
          loop={true}
          grabCursor={true}
          pagination={{
            type: "bullets",
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 80,
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 80,
            },
            1500: {
              slidesPerView: 3,
              spaceBetween: 80,
            },
            1700: {
              slidesPerView: 4,
              spaceBetween: 80,
            },
          }}
        >
          {filterWorkBySize(filterWork)
            .sort((a, b) => ToDate(b._updatedAt) - ToDate(a._updatedAt))
            .map((work, index) => (
              <SwiperSlide key={index} className="cards">
                <CardItem
                  imageUrl={urlFor(work.imgUrl)}
                  title={work.title}
                  description={work.description}
                  projectLink={work.projectLink}
                  codeLink={work.codeLink}
                />
              </SwiperSlide>
            ))}
          <SwiperSlide className="cards">{getGithubData()}</SwiperSlide>
        </Swiper>
      </motion.div>
    </div>
  );
}

export default CardSwiper;
