import React from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import "./CardItem.scss";

const CardItem = ({imageUrl, title, description, projectLink, codeLink}) => {
  return (
    <div className="app__card-item">
      <div className="app__card-img app__flex">
        <img src={imageUrl} alt={title} />

        <motion.div
          whileHover={{ opacity: [0, 1] }}
          transition={{
            duration: 0.25,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          className="app__card-hover app__flex"
        >
          <a
            href={projectLink}
            target="_blank"
            rel="noreferrer"
          >
            <motion.div
              whileInView={{ scale: [0, 1] }}
              whileHover={{ scale: [1, 0.9] }}
              transition={{ duration: 0.25 }}
              className="app__flex"
            >
              <AiFillEye />
            </motion.div>
          </a>
          <a
            href={codeLink}
            target="_blank"
            rel="noreferrer"
          >
            <motion.div
              whileInView={{ scale: [0, 1] }}
              whileHover={{ scale: [1, 0.9] }}
              transition={{ duration: 0.25 }}
              className="app__flex"
            >
              <AiFillGithub />
            </motion.div>
          </a>
        </motion.div>
      </div>

      <div className="app__card-content app__flex">
        <h4 className="bold-text">{title}</h4>
        <p className="p-text" style={{ marginTop: 10 }}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default CardItem;
