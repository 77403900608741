import React, { useState } from "react";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import { BsTwitter, BsInstagram, BsGithub, BsLinkedin } from "react-icons/bs";

import { HiOutlineSun, HiMoon } from "react-icons/hi";

import "./Navbar.scss";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
};

const sections = ["home", "about", "work", "experience", "contact"];

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);

  const handleChangeTheme = () => {
    setDarkTheme(!darkTheme);
    document.body.classList.toggle("dark-theme");
  };

  return (
    <nav
      className="app__navbar"
      style={
        darkTheme
          ? {
              background: "rgba(255, 255, 255, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.07)",
            }
          : {
              background: "rgba(255, 255, 255, 0.25)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }
      }
    >
      <div className="app__navbar-logo">
        <a href="/">
          <img src={darkTheme?images.logo_dark: images.logo_light} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        {sections.map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-theme">
        {darkTheme ? (
          <HiOutlineSun
            onClick={handleChangeTheme}
            style={{ color: "yellow" }}
          />
        ) : (
          <HiMoon onClick={handleChangeTheme} />
        )}
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt3 onClick={() => setToggle(true)} />

        {
          <motion.div
            className="app__navbar-menu-links"
            initial={false}
            animate={toggle ? "open" : "closed"}
            variants={variants}
            transition={{ duration: 1 }}
          >
            <div className="app__navbar-menu-close-btn">
            <HiX onClick={() => setToggle(false)} />

            </div>

            <ul>
              {sections.map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <div className="app__navbar-menu-social">
              <div>
                <a href="https://twitter.com/FabMiguel0312">
                  <BsTwitter />
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/fab_miguel0312/">
                  <BsInstagram />
                </a>
              </div>
              <div>
                <a href="https://github.com/FabioMiguel2000">
                  <BsGithub />
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/f%C3%A1bio-huang-1331bb232/">
                  <BsLinkedin />
                </a>
              </div>
            </div>
          </motion.div>
        }
      </div>
    </nav>
  );
};

export default Navbar;
