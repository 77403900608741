import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

const descriptionVariant = {
  opacity: [0, 1],
  transition: {
    delay: 1,
    duration: 1,
  },
};

const About = () => {
  return (
    <>
      <h2 className="head-text">About Me</h2>
      <div className="app__profiles">
        <div className="app__profile-wrapper">
          <motion.div
            className="app__profile-image"
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1 }}
          >
            <img src={images.profile} alt="profile_image" />
          </motion.div>
          <motion.div
            className="app__profiles-description-container"
            whileInView={descriptionVariant}
            variant={descriptionVariant}
          >
            <h3 className="bold-text">Fabio Huang</h3>
            <p className="p-text">
              Informatics and Computing Engineering Student
            </p>

            <motion.div className="app__profiles-description">
              <p className="p-text">
                Over the past 3 years, I have immersed myself in the dynamic
                world of software development, delving into the intricacies of
                building applications that both function and fascinate. My
                journey has equipped me with front and back-end development
                skills, but my true passion lies in crafting engaging user
                experiences and enhancing the visual appeal of applications.
                While I am not yet an expert, my commitment to continuous
                learning and professional growth is strong. I am working to
                refine my skills and deepen my understanding of advanced
                software development techniques.
                <br />
                <br />
                Currently, I am pursuing a Master's in Informatics and Computing
                Engineering at FEUP in Portugal. The challenges I encounter are
                stepping stones, pushing me towards personal and professional
                growth. Each struggle signifies progression, and I am eager to
                see where this path takes me.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
